import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import CardImage from '../images/card.png';
import LogoImage from '../images/logo.png';

import DeliveryIcon from '../images/delivery.png';
import CouponIcon from '../images/gift-voucher.png';
import ProductIcon from '../images/product.png';
import OrderIcon from '../images/order.png';
import CreateOrderIcon from '../images/create-order.png';
import CartIcon from '../images/shopping-cart.png';

import CustomersIcon from '../images/customers.png';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target))
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 flex-shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink exact to="/dashboard" className="block">
            {sidebarExpanded ? (
              <img className="h-8 w-auto" src={CardImage} alt="Logo" />
            ) : (
              <img className="h-8 w-auto" src={LogoImage} alt="Logo" />
            )}
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pagine</span>
            </h3>
            <ul className="mt-3">
              {/* Dashboard */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('customers') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/customers"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('customers') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={CustomersIcon} alt="clienti" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Clienti
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('product') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/products"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('product') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={ProductIcon} alt="product" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Prodotti
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('cart') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/carts"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('cart') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={CartIcon} alt="cart" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Carrelli
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('orders') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/orders"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('orders') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={OrderIcon} alt="ordini" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Ordini
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('createorder') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/createorder"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('createorder') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={CreateOrderIcon} alt="creaordine" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Crea Ordine
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('coupons') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/coupons"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('coupons') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img src={CouponIcon} alt="coupon" className="h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Coupons
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('deliveryareas') && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/deliveryareas"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes('deliveryareas') && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <img className="h-8 w-auto" src={DeliveryIcon} alt="Delivery" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Zone di consegna
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path
                  className="text-gray-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-gray-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
