import React from 'react';
import moment from 'moment';
import { parseStatus } from '../../utils/Status';

function OrdersTableItem(props) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-light-blue-500">{props.number}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {props.customer?.name} {props.customer?.surname}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-gray-800">{parseStatus(props.order_status)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => {
            window.location.href = '/neworder?' + props.id;
          }}
        >
          Modifica Ordine
        </button>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-gray-800">
          {moment(props.delivery?.date).format('D MMM')}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-gray-800">{props.delivery?.time}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-gray-800">{props.delivery?.modalita}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-gray-800">{props.delivery_city}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-green-500">{props.total} &euro;</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{props.delivery?.date ? moment(props.delivery?.date).format('ll LT') : ''} </div>
      </td>
      {/* ['created', 'completed', 'rejected', 'accepted', 'delivering', 'delivered']*/}
    </tr>
  );
}

export default OrdersTableItem;
