import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import AuthContext from '../store/authContext';

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';
import Logo from '../images/logo.png';

function Signin() {
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');
  const DELTA_TIME = 3000000 * 1000;

  const signIn = (e) => {
    e.preventDefault();
    setErrorMessage('');
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email === 'admin@themeat.it' && password === 'admin') {
      console.log('Sign in successful');
      const expirationTime = new Date(new Date().getTime() + DELTA_TIME);
      authCtx.login('token', expirationTime.toISOString());
      history.push('/dashboard');
    } else {
      setErrorMessage('Credenziali non valide');
    }
  };
  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="h-8 w-auto" src={Logo} alt="Decoration" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-4 py-8">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">Bentornato! ✨</h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      Email
                    </label>
                    <input id="email" className="form-input w-full" type="email" ref={emailRef} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                      ref={passwordRef}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/reset-password">
                      Password dimenticata?
                    </Link>
                  </div>
                  <button
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                    onClick={(e) => signIn(e)}
                  >
                    Sign In
                  </button>
                </div>
                <div className="flex justify-between mt-2 float-right">
                  <div className="text-sm text-red-600">{errorMessage}</div>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{' '}
                  <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/signup">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Signin;
