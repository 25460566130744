import React from 'react';
import moment from 'moment';

function CartTableItem(props) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-light-blue-500">{props.id}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{moment(props.date).format('ll LT')} </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-green-500">{props.total} &euro;</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{parseStatus(props.cart_status)}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{props.items.length}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <button
          className="btn border-gray-200 hover:border-gray-300 text-gray-600"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/createorder?' + props.id;
          }}
        >
          <span className="xs:block ml-2">Vai al carrello</span>
        </button>
      </td>
      {/* ['created', 'completed', 'rejected', 'accepted', 'delivering', 'delivered']*/}
    </tr>
  );
}

function parseStatus(status) {
  switch (status) {
    case 'created':
      return 'CREATO';
    case 'completed':
      return 'COMPLETATO';
    case 'rejected':
      return 'RIFIUTATO';
    case 'accepted':
      return 'ACCETTATO';
    case 'delivering':
      return 'IN CONSEGNA';
    case 'delivered':
      return 'CONSEGNATO';
    default:
      return '';
  }
}

export default CartTableItem;
