export const options = [
  {
    id: 0,
    value: 'MBURGER',
    type: 'mburger',
  },
  {
    id: 1,
    value: 'BISTECCHE',
    type: 'bistecche',
  },
  {
    id: 2,
    value: 'FILETTI',
    type: 'filetti',
  },
  {
    id: 3,
    value: 'MANZO',
    type: 'manzo',
  },
  {
    id: 4,
    value: 'POLLO',
    type: 'pollo',
  },
  {
    id: 5,
    value: 'MAIALE',
    type: 'maiale',
  },
  {
    id: 6,
    value: 'CANTINA',
    type: 'cantina',
  },
];

export const attributesOptions = [
  {
    id: 0,
    value: '',
    type: '',
  },
  {
    id: 1,
    value: 'Griglia',
    type: 'griglia',
  },
  {
    id: 2,
    value: 'Filiera controllata',
    type: 'filiera-controllata',
  },
  {
    id: 3,
    value: 'Authentic MEAT Experience',
    type: 'authentic-meat-exp',
  },
  {
    id: 4,
    value: 'Carne magra',
    type: 'carne-magra',
  },
  {
    id: 5,
    value: 'Provenienza Certificata',
    type: 'provenienza-certificata',
  },
  {
    id: 6,
    value: 'No Conservanti',
    type: 'no-conservanti',
  },
  {
    id: 7,
    value: 'Bambini',
    type: 'bambini',
  },
  {
    id: 8,
    value: 'Cotture Veloci',
    type: 'cotture-veloci',
  },
  {
    id: 9,
    value: 'Black Angus',
    type: 'black-angus',
  },
  {
    id: 10,
    value: '100 Italiana',
    type: '100-italiana',
  },
  {
    id: 11,
    value: 'Tagli Nobili',
    type: 'tagli-nobili',
  },
  {
    id: 12,
    value: 'Padella',
    type: 'padella',
  },
  {
    id: 13,
    value: 'KM 0',
    type: 'km-0',
  },
  {
    id: 14,
    value: 'M burger',
    type: 'mburger',
  },
  {
    id: 15,
    value: 'Primi tagli',
    type: 'primi-tagli',
  },
  {
    id: 16,
    value: 'Selezione Premium',
    type: 'selezione-premium',
  },
];
