import React, { useState, useEffect } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import OrderPage from '../../partials/orders/OrderPage';
import { http } from '../../utils/Utils';

async function POST(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function CreateOrder() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [cart, setCart] = useState({});
  const [products, setProducts] = useState([]);
  const [setCount] = useState(0);
  const [cartId, setCartId] = useState();

  const cartIdSet = async () => {
    const rand = Math.round(Math.random() * 100000);
    window.location.search = rand;

    await POST(process.env.REACT_APP_BASE_URL + '/cart/admin/' + rand).then((data) => {
      console.log(data);
    });

    return rand;
  };

  useEffect(() => {
    const cartId = window.location.search.substring(1)
      ? window.location.search.substring(1)
      : cartIdSet();
    console.log(cartId);
    setCartId(cartId);
    fetchProducts();
    fetchCart(cartId);
  }, []);

  const update = (newCart) => {
    console.log(newCart);
    setCart(newCart);
  };

  const fetchCart = async (cartId) => {
    const cart = await http({
      url: process.env.REACT_APP_BASE_URL + '/cart/' + cartId,
    });
    console.log('cart: ', cart);
    setCart(cart);
  };

  const fetchAllProducts = (skipValue, limitValue) =>
    http({
      url:
        process.env.REACT_APP_BASE_URL + '/product/all?skip=' + skipValue + '&limit=' + limitValue,
    });

  const fetchProducts = async (skipValue, limitValue) => {
    const products = await fetchAllProducts(skipValue, limitValue);
    console.log('products: ', products);
    setProducts(products);
  };

  // const fetchProd = async () => {
  //   console.log(cartId);

  // await fetch(process.env.REACT_APP_BASE_URL + '/cart/' + cartId)
  //   .then((res) => res.json())
  //   .then((result) => {
  //     setCart(result);
  //     var subtotale = 0;

  //     result.items.forEach((p) => {
  //       if (!isNaN(p.price_per_unit) && !isNaN(p.quantity))
  //         subtotale += p.price_per_unit * p.quantity;
  //     });
  //     setSubTotal(subtotale);
  //     setFirstUpdate(true);
  //   });
  //};

  const createOrder = async () => {
    if (cart.total_price > 0) {
      console.log(cart);
      await POST(process.env.REACT_APP_BASE_URL + '/order/admin', { cart_id: cart._id }).then(
        (order) => {
          console.log(order);
          // update();
          // console.log(data);
          window.location.href = '/neworder?' + order._id;
        },
      );
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto overflow-y-auto overflow-x-hidden">
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                Crea Ordine : {cartId}
              </h1>
              <div className="flex justify-between border rounded-md p-2">
                <ul className="list-disc">
                  {cart?.items?.map((c) => {
                    return (
                      <ul className="list-disc ml-4" key={c._id}>
                        {c.slug && (
                          <li>
                            {c.slug} x {c.quantity} - {c.price_per_unit}€ a pezzo - peso
                            {c.weight}g
                          </li>
                        )}
                      </ul>
                    );
                  })}
                </ul>
                <div className="">
                  <div className="font-bold text-2xl">
                    Subtotale: {cart.total_price ? cart.total_price + ' €' : 0}
                  </div>
                  <div>
                    {cart.total_price > 0 && (
                      <button
                        className="flex btn bg-indigo-500 hover:bg-indigo-600 text-white my-auto"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          createOrder();
                        }}
                      >
                        <svg
                          className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="hidden xs:block ml-2">Completa Ordine</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <OrderPage update={update} cartId={cartId} products={products} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CreateOrder;
