import React, { useState, useEffect } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';

import ProductTable from '../../partials/product/ProductTable';
import PaginationClassic from '../../components/PaginationClassic';
import { http } from '../../utils/Utils';

function Products() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [count, setCount] = useState(0);

  const handleClick = (skipUpdate) => {
    setSkip(skipUpdate);
    fetchProducts(skipUpdate, 10);
  };

  const fetchAllProducts = (skipValue, limitValue) =>
    http({
      url:
        process.env.REACT_APP_BASE_URL + '/product/all?skip=' + skipValue + '&limit=' + limitValue,
    });

  const fetchProducts = async (skipValue, limitValue) => {
    const products = await fetchAllProducts(skipValue, limitValue);
    setCount(products.count);
    setProducts(products);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Prodotti</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/products/add';
                  }}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Aggiungi Prodotto</span>
                </button>
              </div>
            </div>

            {/* Table */}
            <ProductTable products={products} />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                count={count}
                skip={skip}
                limit={limit}
                updateSkipHandler={handleClick}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Products;
