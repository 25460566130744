import React from 'react';
import moment from 'moment';

function CouponsTableItem(props) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-light-blue-500">{props.code}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {props.discount?.discount_type === 'freedelivery'
            ? showFreeShipping()
            : showDiscount(props.discount)}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.usage}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.coupons_left}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {moment(props.createdAt).format('D/M/y HH:mm:ss')}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {moment(props.updatedAt).format('D/M/y HH:mm:ss')}
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `/coupons/${props.code}`;
          }}
        >
          Dettagli
        </button>
      </td>
    </tr>
  );
}

function showDiscount(discount) {
  return `${discount?.value} ${mapDiscount(discount?.discount_type)}`;
}

function showFreeShipping() {
  return 'FREE SHIPPING';
}

function mapDiscount(type) {
  switch (type) {
    case 'amount':
      return '€';
    case 'percentage':
      return '%';
    default:
      return '';
  }
}

export default CouponsTableItem;
