import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import Product from './ProductTableItem';

function ProductTable({ selectedItems, products }) {
  // const [selectAll, setSelectAll] = useState(false);
  // const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  return (
    <div className="bg-white shadow-lg overflow-auto rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          Prodotti <span className="text-gray-400 font-medium">{products.count}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Nome</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Tipo</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Peso</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Prezzo</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {products.products
                ?.slice()
                .reverse()
                .map((product, index) => {
                  return (
                    <Product
                      key={index}
                      id={product._id}
                      name={product.name}
                      weight={product.weight}
                      type={product.type}
                      price={product.price}
                      image={product.image}
                      outOfStock={product.outOfStock}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProductTable;
