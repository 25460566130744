import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import DropdownClassic from '../../components/DropdownClassic';
import DropdownFull from '../../components/DropdownFull';
import UploadFileToS3 from '../../components/UploadFileToS3';
import { options, attributesOptions } from '../../pages/products/productData';

import { http } from '../../utils/Utils';

function AddProduct() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [product] = useState({});
  const nameInputRef = useRef();
  const typeInputRef = useRef('');
  const weightInputRef = useRef();
  const priceInputRef = useRef();
  const attributesInputRef = useRef([]);
  const descriptionInputRef = useRef('');
  const shortDescriptionInputRef = useRef('');
  const infoInputRef0 = useRef('');
  const infoInputRef1 = useRef('');
  const infoInputRef2 = useRef('');
  const infoInputRef3 = useRef('');
  const [imageUrl, setImageUrl] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const additionalDescriptionRef = useRef('');
  // const additionalDeliveryRef = useRef('');

  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const pull_value = (data) => {
    typeInputRef.current = options[data].type;
  };

  const pull_productAttributes0 = (data) => {
    return pull_productAttributes(data, 0);
  };

  const pull_productAttributes1 = (data) => {
    return pull_productAttributes(data, 1);
  };

  const pull_productAttributes2 = (data) => {
    return pull_productAttributes(data, 2);
  };

  const pull_productAttributes = (data, element) => {
    attributesInputRef.current[element] = attributesOptions[data].type;
  };

  const onProductImageUpdate = (url) => {
    setImageUrl(url);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const makePath = (input) => {
    const slug = input.toLowerCase().replace(/\s/g, '-');
    const path = slug[0] === '/' ? slug : '/' + slug;
    return { path, slug };
  };

  const addProductHandler = async (event) => {
    if (!nameInputRef.current.value) {
      alert('Per favore inserisci un nome per il prodotto');
      return;
    }

    event.preventDefault();

    const product = {
      name: nameInputRef.current.value,
      path: makePath(nameInputRef.current.value).path,
      slug: makePath(nameInputRef.current.value).slug,
      type: typeInputRef.current,
      weight: weightInputRef.current.value,
      attributes: attributesInputRef.current,
      description: descriptionInputRef.current.value,
      shortDescription: shortDescriptionInputRef.current.value,
      shortDescriptionHtml: [
        infoInputRef0.current.value,
        infoInputRef1.current.value,
        infoInputRef2.current.value,
        infoInputRef3.current.value,
      ],
      image: imageUrl,
      images: {
        url: '/assets/placeholder.png',
        altText: 'Placeholder',
        width: 1000,
        height: 1000,
      },
      price: { value: priceInputRef.current.value },
      sideText: [
        {
          header: nameInputRef.current.value,
          text: [additionalDescriptionRef.current.value],
        },
        {
          header: 'Informazioni aggiuntive',
          text: [''],
        },
        {
          header: 'Ricetta',
          text: [''],
        },
        {
          header: 'Consegna',
          text: [
            "Per gli ordini effettuati nei territori di Napoli, Caserta e Hinterland, consegniamo con il nostro servizio logistico interno. Dopo aver effettuato l'ordine, verrete contattati dal nostro servizio clienti per concordare l'orario e la data della consegna. Tra il confezionamento e la consegna non passeranno più di 4 ore. La freschezza è garantita da veicoli opportunamente refrigerati. La consegna è effettuata al piano tramite un nostro consulente pronto a rispondere a dubbi/domande.",
          ],
        },
      ],
    };

    http({
      url: process.env.REACT_APP_BASE_URL + '/product',
      method: 'POST',

      form: {
        ...product,
      },
    })
      .then((data) => {
        console.log(data);
        setSubmitMessage('Prodotto aggiunto con successo');
        history.push('/products');
      })
      .catch((error) => {
        console.log(error);
        setSubmitMessage(
          "Errore nell'aggiunta del prodotto \n Ricorda che il nome e lo slug devono essere univoci.",
        );
      });
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <form onSubmit={preventDefault}>
            <div className="flex-grow">
              {/* Panel body */}
              <div className="p-6 space-y-6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/products';
                  }}
                  className={'btn bg-white border-gray-200 hover:border-gray-300 text-indigo-500'}
                  href="#0"
                >
                  &lt;- Torna ai Prodotti
                </button>
                <h2 className="text-2xl text-gray-800 font-bold mb-5">Aggiungi nuovo prodotto</h2>
                {/* Business Profile */}

                <section>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="name">
                        Nome
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="name"
                        className="form-input w-full"
                        type="text"
                        required={true}
                        ref={nameInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="type">
                        Tipo
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <DropdownClassic type="button" options={options} func={pull_value} />
                    </div>
                  </div>

                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="attributes">
                        Attributi
                      </label>
                    </div>
                    <div className="w-full">
                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        func={pull_productAttributes0}
                      />

                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        func={pull_productAttributes1}
                      />

                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        func={pull_productAttributes2}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20"
                        htmlFor="shortDescription"
                      >
                        Descrizione Breve
                      </label>
                    </div>
                    <div className="w-full">
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={shortDescriptionInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="description">
                        Descrizione Lunga
                      </label>
                    </div>
                    <div className="w-full">
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={descriptionInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20 align-top"
                        htmlFor="description"
                      >
                        Testi aggiuntivi
                      </label>
                    </div>
                    <div className="w-full">
                      Descrizione centro pagina
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={additionalDescriptionRef}
                      />
                      {/* Consegna
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={additionalDeliveryRef}
                      /> */}
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20 align-top"
                        htmlFor="description"
                      >
                        Info (menu laterale)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef0}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef1}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef2}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef3}
                      ></input>
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="weight">
                        Peso (g)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="name"
                        className="form-input w-full"
                        type="number"
                        ref={weightInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="price">
                        Prezzo (&euro;)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="price"
                        className="form-input w-full"
                        type="number"
                        step={0.01}
                        ref={priceInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="price">
                        Immagine
                      </label>
                    </div>
                    <div>
                      <UploadFileToS3 onClick={onProductImageUpdate} />
                    </div>
                  </div>
                </section>
              </div>
              {/* Panel footer */}
              <footer>
                <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                  <div className="flex self-end">
                    <div>
                      <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                        onClick={addProductHandler}
                      >
                        Aggiungi Prodotto
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 flex self-end"> {submitMessage}</div>
                </div>
              </footer>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
}

export default AddProduct;
