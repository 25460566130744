import React from 'react';
import moment from 'moment';

function DeliveryAreasTableItem(props) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-light-blue-500">{props.deliveryArea?.name}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.deliveryArea?.cap_range?.from}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.deliveryArea?.cap_range?.to}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">{props.deliveryArea?.delivery_cost} &euro;</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {moment(props.deliveryArea?.createdAt).format('D/M/y HH:mm:ss')}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-medium text-gray-800">
          {moment(props.deliveryArea?.updatedAt).format('D/M/y HH:mm:ss')}
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">Dettagli</button>
      </td>
    </tr>
  );
}

// function mapDiscount(type) {
//   switch (type) {
//     case 'amount':
//       return '€';
//     case 'percentage':
//       return '%';
//     default:
//       return '';
//   }
// }

export default DeliveryAreasTableItem;
