import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';

async function POST(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function OrderPage({ update, cartId, products }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  const postCart = async (prod) => {
    const cartProduct = {
      price_per_unit: prod.price.value,
      quantity: 1,
      name: prod.name,
      slug: prod.slug,
      weight: prod.weight,
    };
    console.log(cartProduct);
    await POST(process.env.REACT_APP_BASE_URL + '/cart/admin/' + cartId, { ...cartProduct }).then(
      (cart) => {
        update(cart);
      },
    );
  };

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6">
        {/* Connected Apps cards */}
        <section className="pb-6 border-b border-gray-200">
          <div className="grid grid-cols-12 gap-6">
            {products.products?.map((product, index) => {
              return (
                <div
                  key={index}
                  className="col-span-full md:col-span-4 xl:col-span-3 2xl:col-span-2 bg-white shadow-md rounded-sm border border-gray-200"
                >
                  <div className="flex flex-col h-full p-5">
                    <div className="flex-grow">
                      <h3 className="text-lg font-semibold text-gray-800 m-auto align-left">
                        {product.name}
                      </h3>
                      <h3 className="text-lg m-auto">({product.type ? product.type : ''})</h3>
                      <div className="content-end">
                        <div className="text-lg py-2 content-end">
                          <input
                            className="border-4 w-24"
                            placeholder={
                              product.price && product.price.value ? product.price.value + ' €' : ''
                            }
                            onChange={(e) => {
                              let prodTemp = product;
                              prodTemp.price.value = parseInt(e.target.value);
                              console.log(prodTemp.price.value);
                            }}
                          ></input>
                          <span className="text-sm"> Prezzo</span>
                        </div>
                        <div className="text-lg py-2">
                          <input
                            className="border-4 w-24"
                            placeholder={product.weight}
                            onChange={(e) => {
                              let prodTemp = product;
                              prodTemp.weight = parseInt(e.target.value);
                            }}
                          ></input>
                          <span className="text-sm"> Peso(g)</span>
                        </div>
                      </div>
                      <span className="">
                        {/* Right side */}
                        <button
                          className="content-end btn-lg border-gray-200 hover:border-gray-300 shadow-sm"
                          onClick={() => {
                            postCart(product);
                          }}
                        >
                          <span>Aggiungi +</span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}

export default OrderPage;
