import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import Carts from './CartTableItem';

function CartTable({ selectedItems, carts }) {
  // const [selectAll, setSelectAll] = useState(false);
  // const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(carts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   setIsCheck(list.map(li => li.id));
  //   if (selectAll) {
  //     setIsCheck([]);
  //   }
  // };

  // const handleClick = e => {
  //   const { id, checked } = e.target;
  //   setSelectAll(false);
  //   setIsCheck([...isCheck, id]);
  //   if (!checked) {
  //     setIsCheck(isCheck.filter(item => item !== id));
  //   }
  // };

  // useEffect(() => {
  //   selectedItems(isCheck);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          Numero Carrelli&nbsp;<span className="text-gray-400 font-medium">{carts.count}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Codice carrello</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Creato il</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Prezzo</div>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Stato carrello</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Articoli carrello</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {carts &&
                carts.carts &&
                carts.carts.map((cart, index) => {
                  return (
                    <Carts
                      key={cart._id}
                      id={cart._id}
                      date={cart.createdAt}
                      total={cart.total_price}
                      cart_status={cart.cart_status}
                      items={cart.items}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CartTable;
