export const parseStatus = (value) => {
  switch (value) {
    case 'created':
      return 'CREATO';
    case 'completed':
      return 'EFFETTUATO';
    case 'rejected':
      return 'RIFIUTATO';
    case 'accepted':
      return 'ACCETTATO';
    case 'delivering':
      return 'IN CONSEGNA';
    case 'delivered':
      return 'CONSEGNATO';
    default:
      return value;
  }
};
