import React, { useState, useEffect } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import { http } from '../../utils/Utils';
import OrdersTable from '../../partials/orders/OrdersTable';

function CouponDetails() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [orders, setOrders] = useState([]);

  const couponCode = window.location.pathname.split('/')[2];
  console.log(`couponCode from string: ${couponCode}`);

  const convertDiscountType = (discountType) => {
    switch (discountType) {
      case 'amount':
        return 'EURO';
      case 'freedelivery':
        return 'FREE DELIVERY';
      case 'percentage':
        return 'PERCENTUALE';
      default:
        return discountType;
    }
  };

  const fetchCoupon = (couponCode) =>
    http({
      url: '/coupon/' + couponCode,
    });

  const getCoupon = async (couponCode) => {
    console.log(`Coupon code: ${couponCode}`);
    try {
      const couponData = await fetchCoupon(couponCode);
      setCoupon(couponData.coupon);
      setOrders(couponData.orders);
      console.log(orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoupon(couponCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="flex-grow">
            {/* Panel body */}
            <div className="p-6 space-y-6">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/coupons';
                }}
                className={'btn bg-white border-gray-200 hover:border-gray-300 text-indigo-500'}
                href="#0"
              >
                &lt;- Torna ai Coupon
              </button>
              <h2 className="text-2xl text-gray-800 font-bold mb-5">Dettagli coupon</h2>
              {/* Business Profile */}
              <section>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="coupon">
                      Codice
                    </label>
                    <input
                      id="coupon"
                      className="form-input w-full"
                      type="text"
                      value={coupon.code}
                    />
                  </div>
                </div>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="discountvalue">
                      Sconto
                    </label>
                    <input
                      id="discountvalue"
                      className="form-input w-full"
                      type="number"
                      value={coupon?.discount?.value}
                    />
                  </div>
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="discounttype">
                      Tipo Sconto
                    </label>
                    <input
                      id="discounttype"
                      className="form-input w-full"
                      type="text"
                      value={convertDiscountType(coupon?.discount?.discount_type)}
                    />
                  </div>
                </div>
                {coupon?.availability_dates ? (
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div className="sm:w-1/3">
                      <label className="block text-sm font-medium mb-1" htmlFor="validfrom">
                        Valido da
                      </label>
                      <input
                        id="validfrom"
                        className="form-input w-full"
                        type="text"
                        value={
                          new Date(coupon?.availability_dates.from).getDate() +
                          '/' +
                          (new Date(coupon?.availability_dates.from).getMonth() + 1) +
                          '/' +
                          new Date(coupon?.availability_dates.from).getFullYear()
                        }
                      />{' '}
                    </div>
                    <div className="sm:w-1/3">
                      <label className="block text-sm font-medium mb-1" htmlFor="validto">
                        Valido fino a
                      </label>
                      <input
                        id="validto"
                        className="form-input w-full"
                        type="text"
                        value={
                          new Date(coupon?.availability_dates.to).getDate() +
                          '/' +
                          (new Date(coupon?.availability_dates.to).getMonth() + 1) +
                          '/' +
                          new Date(coupon?.availability_dates.to).getFullYear()
                        }
                      />{' '}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5 mb-4">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="usage">
                      Coupon usati
                    </label>
                    <input
                      id="usage"
                      className="form-input w-full"
                      type="number"
                      min="0"
                      value={coupon.usage}
                    />
                  </div>
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="couponamount">
                      Coupon rimasti
                    </label>
                    <input
                      id="coupons-number"
                      className="form-input w-full"
                      type="number"
                      min="0"
                      value={coupon.coupons_left}
                    />
                  </div>
                </div>
                {orders ? <OrdersTable orders={orders} /> : 'No orders'}
              </section>
              {orders ? orders.length : ''}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CouponDetails;
