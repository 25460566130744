import { useState } from 'react';
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require('buffer').Buffer;

const UploadFileToS3 = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = async (file) => {
    if (file) {
      const ReactS3Client = new S3(config);
      setErrorMessage('');
      ReactS3Client.uploadFile(file, file.name)
        .then((data) => {
          setImage(data.location);
          props.onClick(data.location);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setErrorMessage('Nessuna immagine selezionata');
    }
  };

  return (
    <div>
      <div>
        {image && <img src={image} width={200} alt={'Uploaded file'} />}

        <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <span className="mt-2 text-base leading-normal">Seleziona Immagine</span>
          <input type={'file'} className="hidden" onChange={handleFileInput} />
        </label>
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
          onClick={(e) => {
            e.preventDefault();
            console.log('selectedFile', selectedFile);
            uploadFile(selectedFile);
          }}
        >
          {' '}
          Carica Immagine
        </button>
        <div className="mt-2 text-red-500">{errorMessage}</div>
      </div>
    </div>
  );
};

export default UploadFileToS3;
