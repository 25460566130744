import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../css/tailwind.config.js';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://themeat-api.herokuapp.com';

export async function http({ method, url, form, json, text, headers }) {
  const fullUrl = url.indexOf('http') === 0 ? url : BASE_URL + url;
  const contentTypeHeader =
    !text && json !== false && !(form && form instanceof FormData)
      ? { 'Content-Type': 'application/json' }
      : {};
  const accessTokenHeader = headers ? headers : {};

  const res = await fetch(fullUrl, {
    method: method || 'GET',
    headers: {
      ...contentTypeHeader,
      ...accessTokenHeader,
    },
    body: !form ? undefined : form instanceof FormData ? form : JSON.stringify(form),
  });
  let content = {};
  if (json !== false) {
    try {
      content = await res.json();
    } catch (e) {}
  }
  if (text) {
    try {
      content = await res.text();
    } catch (e) {}
  }
  if (!res.ok) {
    // eslint-disable-next-line
    throw {
      code: res.status,
      reason: (content || {})?.errmsg || content || res.statusText || 'Error',
    };
  } else {
    return content;
  }
}

export const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
