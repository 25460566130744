import React, { useContext, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';

import './css/style.scss';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';
import AuthContext from './store/authContext';

// Import pages
import Account from './pages/settings/Account';
import AddCoupon from './pages/coupons/AddCoupon';
import AddProduct from './pages/products/AddProduct';
import Analytics from './pages/Analytics';
import Apps from './pages/settings/Apps';
import Billing from './pages/settings/Billing';
import Calendar from './pages/Calendar';
import Cart from './pages/ecommerce/Cart';
import Cart2 from './pages/ecommerce/Cart2';
import Carts from './pages/carts/Carts';
import Coupons from './pages/coupons/Coupons';
import CreateOrder from './pages/orders/CreateOrder';
import Customers from './pages/ecommerce/Customers';
import Dashboard from './pages/Dashboard';
import DeliveryAreas from './pages/deliveryareas/DeliveryAreas';
import EmptyState from './pages/utility/EmptyState';
import Faqs from './pages/utility/Faqs';
import Feedback from './pages/settings/Feedback';
import Invoices from './pages/ecommerce/Invoices';
import NewOrder from './pages/orders/NewOrder';
import Notifications from './pages/settings/Notifications';
import Orders from './pages/orders/Orders';
import PageNotFound from './pages/utility/PageNotFound';
import Plans from './pages/settings/Plans';
import Products from './pages/products/Products';
import ResetPassword from './pages/ResetPassword';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import UpdateProduct from './pages/products/UpdateProduct';
import DropdownPage from './pages/component/DropdownPage';
import CouponDetails from './pages/coupons/CouponDetails';

function App() {
  const location = useLocation();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Signin />
        </Route>
        <Route exact path="/dashboard">
          {authCtx.isLoggedIn && <Dashboard />}
          {/* {!authCtx.isLoggedIn && <Signin />} */}
        </Route>
        <Route exact path="/analytics">
          <Analytics />
        </Route>
        <Route exact path="/carts">
          <Carts />
        </Route>
        <Route exact path="/customers">
          <Customers />
        </Route>
        <Route exact path="/orders">
          <Orders />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/products/add">
          <AddProduct />
        </Route>
        <Route exact path="/products/update/:id">
          <UpdateProduct />
        </Route>
        <Route exact path="/coupons">
          <Coupons />
        </Route>
        <Route exact path="/coupons/add">
          <AddCoupon />
        </Route>
        <Route exact path="/coupons/:id">
          <CouponDetails />
        </Route>
        <Route exact path="/deliveryareas">
          <DeliveryAreas />
        </Route>
        <Route exact path="/neworder">
          <NewOrder />
        </Route>
        <Route exact path="/createorder">
          <CreateOrder />
        </Route>

        <Route exact path="/ecommerce/invoices">
          <Invoices />
        </Route>
        <Route exact path="/ecommerce/cart">
          <Cart />
        </Route>
        <Route exact path="/ecommerce/cart-2">
          <Cart2 />
        </Route>
        <Route exact path="/calendar">
          <Calendar />
        </Route>
        <Route exact path="/settings/account">
          <Account />
        </Route>
        <Route exact path="/settings/notifications">
          <Notifications />
        </Route>
        <Route exact path="/settings/apps">
          <Apps />
        </Route>
        <Route exact path="/settings/plans">
          <Plans />
        </Route>
        <Route exact path="/settings/billing">
          <Billing />
        </Route>
        <Route exact path="/settings/feedback">
          <Feedback />
        </Route>
        <Route exact path="/utility/faqs">
          <Faqs />
        </Route>
        <Route exact path="/utility/empty-state">
          <EmptyState />
        </Route>
        <Route exact path="/utility/404">
          <PageNotFound />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/dropdown">
          <DropdownPage />
        </Route>
        <Route exact path="/404">
          <PageNotFound />
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
}

export default App;
