import React, { useState, useEffect, useRef } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import DropdownClassic from '../../components/DropdownClassic';
import Datepicker from '../../components/Datepicker';
import { http } from '../../utils/Utils';

function AddCoupon() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [discountType, setDiscountType] = useState(0);
  const [showCodeError, setShowCodeError] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date().getDate() + 90);
  const [showDiscountValueError, setShowDiscountValueError] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const codeInputRef = useRef();
  const discountValueInputRef = useRef();
  const amountInputRef = useRef();

  const options = [
    {
      id: 0,
      value: 'EURO',
      type: 'amount',
    },
    {
      id: 1,
      value: 'PERCENTUALE',
      type: 'percentage',
    },
    {
      id: 2,
      value: 'FREE DELIVERY',
      type: 'freedelivery',
    },
  ];

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const addCouponHandler = (event) => {
    event.preventDefault();
    setShowCodeError(false);
    setShowDiscountValueError(false);
    setShowAmountError(false);
    setShowSuccess(false);
    setShowError(false);

    if (codeInputRef.current.value === 'undefined' || codeInputRef.current.value === '') {
      setShowCodeError(true);
      return;
    }

    if (
      discountValueInputRef.current.value === 'undefined' ||
      discountValueInputRef.current.value === ''
    ) {
      setShowDiscountValueError(true);
      return;
    }

    if (amountInputRef.current.value === 'undefined' || amountInputRef.current.value === '') {
      setShowAmountError(true);
      return;
    }
    const code = codeInputRef.current.value;
    const discount = discountValueInputRef.current.value;
    const amount = amountInputRef.current.value;

    let newCoupon = {
      code: code,
      discount: {
        value: discount,
        discount_type: discountType,
      },
      availability_dates: {
        from: dateFrom,
        to: dateTo,
      },
      coupons_left: amount,
    };
    console.log(newCoupon);

    http({
      url: process.env.REACT_APP_BASE_URL + '/coupon',
      method: 'POST',

      form: {
        ...newCoupon,
      },
    })
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  const pull_value = (data) => {
    setDiscountType(options[data].type);
  };

  const pull_date_from = (data) => {
    setDateFrom(new Date(data));
  };

  const pull_date_to = (data) => {
    setDateTo(new Date(data));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="flex-grow">
            {/* Panel body */}
            <div className="p-6 space-y-6">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/coupons';
                }}
                className={'btn bg-white border-gray-200 hover:border-gray-300 text-indigo-500'}
                href="#0"
              >
                &lt;- Torna ai Coupon
              </button>
              <h2 className="text-2xl text-gray-800 font-bold mb-5">Aggiungi nuovo coupon</h2>
              {/* Business Profile */}
              <form onSubmit={preventDefault}>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="coupon">
                      Codice
                    </label>
                    <input
                      id="coupon"
                      className="form-input w-full"
                      type="text"
                      ref={codeInputRef}
                    />
                    {showCodeError ? (
                      <span className="text-red-600">Aggiungi codice coupon</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="discountvalue">
                      Sconto
                    </label>
                    <input
                      id="business-id"
                      className="form-input w-full"
                      type="number"
                      min="0"
                      ref={discountValueInputRef}
                    />
                    {showDiscountValueError ? (
                      <span className="text-red-600">Aggiungi importo sconto</span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="location">
                      Tipo Sconto
                    </label>
                    <DropdownClassic type="button" options={options} func={pull_value} />
                  </div>
                </div>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="validfrom">
                      Valido da
                    </label>
                    <Datepicker func={pull_date_from} />
                  </div>
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="validto">
                      Valido fino a
                    </label>
                    <Datepicker func={pull_date_to} addDays={90} />
                  </div>
                </div>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5 mb-4">
                  <div className="sm:w-1/3">
                    <label className="block text-sm font-medium mb-1" htmlFor="couponamount">
                      Numero di coupon (quanti coupon vuoi creare?)
                    </label>
                    <input
                      id="coupons-number"
                      className="form-input w-full"
                      type="number"
                      min="0"
                      ref={amountInputRef}
                    />
                    {showAmountError ? (
                      <span className="text-red-600">Aggiungi numero di coupon</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="flex flex-col pr-6 py-5 border-t border-gray-200">
                  {showSuccess ? <span>Coupon creato con successo!</span> : ''}
                  {showError ? (
                    <span>
                      Errore durante l'inserimento: controlla che il codice non sia stato già usato.
                    </span>
                  ) : (
                    ''
                  )}

                  <div className="flex self-end px-6">
                    <button
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                      onClick={addCouponHandler}
                    >
                      Aggiungi Coupon
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AddCoupon;
