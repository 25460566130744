import React, { useState, useEffect, useRef } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import { http } from '../../utils/Utils';
import DropdownClassic from '../../components/DropdownClassic';
import DropdownFull from '../../components/DropdownFull';
import UploadFileToS3 from '../../components/UploadFileToS3';

import { options, attributesOptions } from '../../pages/products/productData';

function UpdateProduct() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [product, setProduct] = useState({});
  const nameInputRef = useRef();
  const pathInputRef = useRef();
  const slugInputRef = useRef();
  const typeInputRef = useRef();
  const weightInputRef = useRef();
  const priceInputRef = useRef();
  const attributesInputRef = useRef([]);
  const descriptionInputRef = useRef('');
  const shortDescriptionInputRef = useRef('');
  const infoInputRef0 = useRef('');
  const infoInputRef1 = useRef('');
  const infoInputRef2 = useRef('');
  const infoInputRef3 = useRef('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const additionalDescriptionRef = useRef('');
  const [outOfStockButtonText, setOutOfstockButtonText] = useState('');

  const getProduct = () =>
    http({
      url: process.env.REACT_APP_BASE_URL + '/product/' + window.location.pathname.split('/')[3],
    });

  const pull_productAttributes0 = async (data) => {
    return pull_productAttributes(data, 0);
  };

  const pull_productAttributes1 = (data) => {
    return pull_productAttributes(data, 1);
  };

  const pull_productAttributes2 = (data) => {
    return pull_productAttributes(data, 2);
  };

  const pull_productAttributes = (data, element) => {
    attributesInputRef.current[element] = attributesOptions[data].type;
  };

  const fetchProduct = async () => {
    const product = await getProduct();
    setProduct(product);
    document.getElementById('myTextArea').value = JSON.stringify(product, undefined, 2);

    nameInputRef.current.value = product.name;
    pathInputRef.current.value = product.path;
    slugInputRef.current.value = product.slug;
    //typeInputRef.current.value = product.type;
    weightInputRef.current.value = product.weight;
    priceInputRef.current.value = product.price.value;
    attributesInputRef.current.value = product.attributes;
    descriptionInputRef.current.value = product.description;
    shortDescriptionInputRef.current.value = product.shortDescription;
    infoInputRef0.current.value = product.shortDescriptionHtml[0];
    infoInputRef1.current.value = product.shortDescriptionHtml[1];
    infoInputRef2.current.value = product.shortDescriptionHtml[2];
    infoInputRef3.current.value = product.shortDescriptionHtml[3];
    additionalDescriptionRef.current.value = product.sideText[0]?.text;
    // additionalDeliveryRef.current.value = product.sideText[3]?.text;
    setImageUrl(product.image);
    setOutOfstockButtonText(product.outOfStock ? 'Riabilita' : 'Disabilita');
  };

  const updateProductVisibility = async (e) => {
    e.preventDefault();
    http({
      url: process.env.REACT_APP_BASE_URL + '/product/' + product._id,
      method: 'PUT',

      form: {
        ...product,
        outOfStock: !product.outOfStock,
      },
    }).then((data) => {
      fetchProduct();
      setOutOfstockButtonText(data.outOfStock ? 'Riabilita' : 'Disabilita');
      setSubmitMessage(data.outOfStock ? 'Prodotto disabilitato' : 'Prodotto riabilitato');
    });
  };

  const makePath = (input) => {
    const slug = input.toLowerCase().replace(/\s/g, '-');
    const path = slug[0] === '/' ? slug : '/' + slug;
    return { path, slug };
  };

  const updateProductHandler = async (event) => {
    if (!nameInputRef.current.value) {
      alert('Per favore inserisci un nome per il tuo prodotto');
      return;
    }
    event.preventDefault();
    setSubmitMessage('');

    const updatedProduct = {
      name: nameInputRef.current.value,
      path: makePath(nameInputRef.current.value).path,
      slug: makePath(nameInputRef.current.value).slug,

      type: typeInputRef.current.value,
      weight: weightInputRef.current.value,
      price: {
        value: Number(priceInputRef.current.value),
      },
      attributes: [
        attributesInputRef.current[0],
        attributesInputRef.current[1],
        attributesInputRef.current[2],
      ],
      description: descriptionInputRef.current.value,
      shortDescription: shortDescriptionInputRef.current.value,
      shortDescriptionHtml: [
        infoInputRef0.current.value,
        infoInputRef1.current.value,
        infoInputRef2.current.value,
        infoInputRef3.current.value,
      ],
      image: imageUrl,
      images: {
        url: '/assets/placeholder.png',
        altText: 'Placeholder',
        width: 1000,
        height: 1000,
      },
      sideText: [
        {
          header: nameInputRef.current.value,
          text: [additionalDescriptionRef.current.value],
        },
        {
          header: 'Informazioni aggiuntive',
          text: [''],
        },
        {
          header: 'Ricetta',
          text: [''],
        },
        {
          header: 'Consegna',
          text: [
            "Per gli ordini effettuati nei territori di Napoli, Caserta e Hinterland, consegniamo con il nostro servizio logistico interno. Dopo aver effettuato l'ordine, verrete contattati dal nostro servizio clienti per concordare l'orario e la data della consegna. Tra il confezionamento e la consegna non passeranno più di 4 ore. La freschezza è garantita da veicoli opportunamente refrigerati. La consegna è effettuata al piano tramite un nostro consulente pronto a rispondere a dubbi/domande.",
          ],
        },
      ],
    };

    http({
      url: process.env.REACT_APP_BASE_URL + '/product/' + product._id,
      method: 'PUT',

      form: {
        ...updatedProduct,
      },
    }).then((data) => {
      setSubmitMessage('Prodotto aggiornato con successo');
    });
  };

  const pull_value = (data) => {
    typeInputRef.current = options[data].type;
    product.value = options[data].value;
  };

  const onProductImageUpdate = (url) => {
    setImageUrl(url);
  };

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <form onSubmit={updateProductHandler}>
            <div className="flex-grow">
              {/* Panel body */}
              <div className="p-6 space-y-6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/products';
                  }}
                  className={'btn bg-white border-gray-200 hover:border-gray-300 text-indigo-500'}
                  href="#0"
                >
                  &lt;- Torna ai Prodotti
                </button>

                <h2 className="text-2xl text-gray-800 font-bold mb-5">
                  Aggiorna prodotto : {product.name} {product.outOfStock ? '(Disabilitato)' : ''}
                </h2>
                {/* Business Profile */}
                <section>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="name">
                        Nome
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="name"
                        className="form-input w-full"
                        type="text"
                        ref={nameInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="path">
                        Path (per url)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="path"
                        className="form-input w-full bg-gray-200"
                        type="text"
                        disabled={true}
                        ref={pathInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="slug">
                        Slug
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="slug"
                        className="form-input w-full bg-gray-200"
                        type="text"
                        disabled={true}
                        ref={slugInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="type">
                        Tipo
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <DropdownClassic
                        type="button"
                        options={options}
                        func={pull_value}
                        selected={product.type}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="attributes">
                        Attributi
                      </label>
                    </div>

                    <div className="w-full">
                      <input
                        id="attributes"
                        className="form-input w-full"
                        type="text"
                        ref={attributesInputRef}
                        hidden={true}
                      />
                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        selected={product.attributes?.[0]}
                        func={pull_productAttributes0}
                      />

                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        selected={product.attributes?.[1]}
                        func={pull_productAttributes1}
                      />

                      <DropdownFull
                        type="button"
                        options={attributesOptions}
                        selected={product.attributes?.[2]}
                        func={pull_productAttributes2}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20"
                        htmlFor="shortDescription"
                      >
                        Descrizione Breve
                      </label>
                    </div>
                    <div className="w-full">
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={shortDescriptionInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="description">
                        Descrizione Lunga
                      </label>
                    </div>
                    <div className="w-full">
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={descriptionInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20 align-top"
                        htmlFor="description"
                      >
                        Testi aggiuntivi
                      </label>
                    </div>
                    <div className="w-full">
                      Descrizione centro pagina
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={additionalDescriptionRef}
                      />
                      {/* Consegna
                      <textarea
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={additionalDeliveryRef}
                      /> */}
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 w-20 align-top"
                        htmlFor="description"
                      >
                        Info (menu laterale)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef0}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef1}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef2}
                      ></input>
                      <input
                        id="type"
                        className="form-input w-full"
                        type="text"
                        ref={infoInputRef3}
                      ></input>
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="weight">
                        Peso (g)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="name"
                        className="form-input w-full"
                        type="number"
                        ref={weightInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="price">
                        Prezzo (&euro;)
                      </label>
                    </div>
                    <div className="sm:w-1/3">
                      <input
                        id="price"
                        className="form-input w-full"
                        type="number"
                        step={0.01}
                        ref={priceInputRef}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                    <div>
                      <label className="block text-sm font-medium mb-1 w-20" htmlFor="price">
                        Immagine
                      </label>
                    </div>
                    {product.image && <img src={product.image} width={400} alt={product.name} />}

                    <UploadFileToS3 onClick={onProductImageUpdate} />
                  </div>
                </section>
              </div>
              {/* Panel footer */}
              <footer>
                <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                  <div className="flex self-end">
                    <div>
                      <button
                        className="btn bg-yellow-500 hover:bg-indigo-600 text-white ml-3"
                        onClick={(e) => updateProductVisibility(e)}
                      >
                        {outOfStockButtonText}
                      </button>
                      <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">
                        Aggiorna Prodotto
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 flex self-end"> {submitMessage}</div>
                </div>
                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                  <div className="w-full">
                    <textarea
                      className="form-input w-full"
                      type="text"
                      id="myTextArea"
                      rows="40"
                    ></textarea>
                  </div>
                </div>
              </footer>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
}

export default UpdateProduct;
