import React, { useState, useEffect } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';

import CartTable from '../../partials/cart/CartTable';
import { http } from '../../utils/Utils';

function Carts() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [carts, setCarts] = useState([]);

  const fetchAllCarts = (skipValue, limitValue) =>
    http({
      url:
        process.env.REACT_APP_BASE_URL +
        '/cart/admin/all?skip=' +
        skipValue +
        '&limit=' +
        limitValue,
    });

  const fetchCarts = async (skipValue, limitValue) => {
    const carts = await fetchAllCarts(skipValue, limitValue);
    setCarts(carts);
  };

  useEffect(() => {
    fetchCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Carrelli</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
            </div>

            {/* Table */}
            <CartTable carts={carts}></CartTable>

            {/* Pagination */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Carts;
