import React, { useState, useEffect } from 'react';
//import { useParams } from 'react-router-dom';
import { http } from '../../utils/Utils';
import { parseStatus } from '../../utils/Status';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';

async function PUT(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function NewOrder() {
  const [order, setOrder] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postCode, setPostCode] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryMode, setDeliveryMode] = useState('');
  const [time, setTime] = useState('');
  const [note, setNote] = useState('');
  const [price, setPrice] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const orderId = window.location.search.substring(1);

  const fetchOrderCall = () =>
    http({
      url: process.env.REACT_APP_BASE_URL + '/order/' + orderId,
    });

  const fetchOrder = async () => {
    try {
      const order = await fetchOrderCall();
      setOrder(order);
      setDeliveryMode(order.delivery?.modalita);
      if (order?.delivery?.date) {
        setDeliveryDate(order?.delivery?.date.toString().substring(0, 10));
      }
      if (order?.delivery?.time) {
        try {
          setTime(order?.delivery?.time);
        } catch (e) {
          console.log(e);
        }
      }
    } catch {
      setOrder(null);
    }
  };

  useEffect(() => {
    fetchOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var params = {
      name: name,
      surname: surname,
      email: email,
      phone_number: phone_number,
      addressLine1: addressLine1,
      city: city,
      province: province,
      postCode: postCode,
      delivery_date: deliveryDate,
      delivery_time: time,
      delivery_mode: deliveryMode,
      note: note,
      price: price,
    };
    var defParams = {};
    for (const property in params) {
      if (params[property]) {
        defParams[property] = params[property];
      }
    }

    const obj = {
      delivery: {},
      customer: {},
      address: {},
      note: params.note,
      price: {
        total: parseInt(params.price) || order.price.total,
      },
    };

    if (defParams.delivery_date) obj.delivery.date = defParams.delivery_date;
    else if (order?.delivery?.date) obj.delivery.date = order.delivery.date;
    if (defParams.delivery_time) obj.delivery.time = defParams.delivery_time;
    else if (order?.delivery?.time) obj.delivery.time = order.delivery.time;
    if (defParams.delivery_mode) obj.delivery.modalita = defParams.delivery_mode;
    else if (order?.delivery?.modalita) obj.delivery.modalita = order.delivery.modalita;
    if (defParams.name) obj.customer.name = defParams.name;
    else if (order?.customer?.name) obj.customer.name = order.customer.name;
    if (defParams.surname) obj.customer.surname = defParams.surname;
    else if (order?.customer?.surname) obj.customer.surname = order.customer.surname;
    if (defParams.email) obj.customer.email = defParams.email;
    else if (order?.customer?.email) obj.customer.email = order.customer.email;
    if (defParams.phone_number) obj.customer.phone_number = defParams.phone_number;
    else if (order?.customer?.phone_number) obj.customer.phone_number = order.customer.phone_number;
    if (defParams.addressLine1) obj.address.addressLine1 = defParams.addressLine1;
    else if (order?.address?.addressLine1) obj.address.addressLine1 = order.address.addressLine1;
    if (defParams.city) obj.address.city = defParams.city;
    else if (order?.address?.city) obj.address.city = order.address.city;
    if (defParams.province) obj.address.province = defParams.province;
    else if (order?.address?.province) obj.address.province = order.address.province;
    if (defParams.postCode) obj.address.postCode = defParams.postCode;
    else if (order?.address?.postCode) obj.address.postCode = order.address.postCode;
    if (defParams.note) obj.note = defParams.note;
    else if (order?.note) obj.note = order.note;

    await PUT(process.env.REACT_APP_BASE_URL + '/order/admin/' + orderId, { ...obj }).then(
      (data) => {
        setMessage('Ordine aggiornato con successo');
        setOrder(data);
      },
    );
  };

  const progressOrder = async (order_status, payment_status) => {
    setErrorMessage('');

    if (
      order.customer?.name === undefined ||
      order.customer?.name === null ||
      order.customer?.name === ''
    ) {
      setErrorMessage('Aggiungi il nome del cliente');
      return;
    }
    if (
      order.customer?.surname === undefined ||
      order.customer?.surname === null ||
      order.customer?.surname === ''
    ) {
      setErrorMessage('Aggiungi il cognome del cliente');
      return;
    }
    if (
      order.customer?.email === undefined ||
      order.customer?.email === null ||
      order.customer?.email === ''
    ) {
      setErrorMessage('Aggiungi la email del cliente');
      return;
    }
    if (order.delivery?.date === undefined || order.delivery?.date === null) {
      setErrorMessage('Aggiungi data di consegna');
      return;
    }
    if (order.delivery?.time === undefined || order.delivery?.time === null) {
      setErrorMessage('Aggiungi orario di consegna');
      return;
    }
    if (
      order.delivery?.modalita === undefined ||
      order.delivery?.modalita === null ||
      order.delivery?.modalita === ''
    ) {
      setErrorMessage('Aggiungi modalità di consegna');
      return;
    }

    http({
      url: process.env.REACT_APP_BASE_URL + '/order/admin/' + orderId + '/progress',
      method: 'PUT',

      form: {
        order_status: order_status,
        payment_status: payment_status,
      },
    }).then((data) => {
      //setMessage('Ordine modificato ----- ' + order_status);
      fetchOrder();
    });
  };

  const ACTIONS = [
    {
      text: 'Accetta ordine',
      status: 'accepted',
      textcolor: 'text-yellow-600',
    },
    {
      text: 'Rifiuta ordine',
      status: 'rejected',
      textcolor: 'text-red-600',
    },
    {
      text: 'In consegna',
      status: 'delivering',
      textcolor: 'text-yellow-600',
    },
    {
      text: 'Consegnato',
      status: 'delivered',
      textcolor: 'text-green-600',
    },
  ];

  return (
    <div className="flex h-screen overflow-auto">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          {order ? (
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    Nuovo Ordine / Modifica Ordine {order.order_number}
                  </h1>
                  <h4 className="font-bold">Stato ordine: {parseStatus(order.order_status)}</h4>
                </div>
              </div>
              <div className="mb-8">
                {ACTIONS.map((action, index) => {
                  return (
                    <button
                      key={index}
                      className={`btn btn bg-white border-gray-200 hover:border-gray-300 mr-6 ${action.textcolor}`}
                      disabled={order.order_status === action.status}
                      onClick={() => {
                        progressOrder(action.status);
                      }}
                    >
                      {action.text}
                    </button>
                  );
                })}
                <div className="mt-4 text-red-600">{errorMessage}</div>
              </div>
              <div>
                <form onSubmit={handleSubmit} id="checkoutform" className="mt-8">
                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Nome
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={name}
                        placeholder={order?.customer?.name || 'Inserisci il nome'}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Cognome
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={surname}
                        placeholder={order?.customer?.surname || 'Inserisci il cognome'}
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Email
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={email}
                        placeholder={order?.customer?.email || 'Inserisci email'}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Telefono
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={phone_number}
                        placeholder={order?.customer?.phone_number || 'Inserisci numero telefono'}
                        onChange={(e) => setPhone_number(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Via e Numero
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={addressLine1}
                        placeholder={order?.address?.addressLine1 || 'Inserisci via'}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        CAP
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={postCode}
                        placeholder={order?.address?.postCode || 'Inserisci codice postale'}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Città
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={city}
                        placeholder={order?.address?.city || 'Inserisci città'}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Provincia
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={province}
                        placeholder={order?.address?.province || 'Inserisci provincia'}
                        onChange={(e) => setProvince(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Data di consegna :
                      </label>
                      <input
                        type="date"
                        value={deliveryDate}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Orario di consegna
                      </label>
                      <select
                        id="delivery_time"
                        name="delivery_time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      >
                        <option value="-">-</option>
                        <option value="12:30-15:00">12:30-15:00</option>
                        <option value="15:00-18:00">15:00-18:00</option>
                        <option value="18:00-20:30">18:00-20:30</option>
                      </select>
                      {/* <input type="time" value={time} onChange={(e) => setTime(e.target.value)} /> */}
                    </div>
                  </div>

                  <div className="grid gap-5 md:grid-cols-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Modalità di consegna
                      </label>
                      <select
                        id="delivery_mode"
                        className=""
                        name="delivery_mode"
                        value={deliveryMode}
                        onChange={(e) => setDeliveryMode(e.target.value)}
                      >
                        <option value="-">-</option>
                        <option value="domicilio">Domicilio</option>
                        <option value="ritiro">Ritiro</option>
                      </select>
                    </div>
                  </div>
                  <div className="grid gap-5 md:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Indicazioni
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={note}
                        placeholder={order?.note || 'Nota consegna'}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid gap-5 md:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Prezzo finale (non modificare per lasciare il prezzo com'è)
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={price}
                        placeholder={order?.price?.total}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid gap-5 md:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Coupon usato
                      </label>
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={order?.discount?.coupon}
                      />
                    </div>
                  </div>

                  <div className="mt-8">
                    {order?.items?.map((c) => {
                      return (
                        <ul className="list-disc ml-4" key={c._id}>
                          <li>
                            {c.slug} {c.quantity} x {c.product} - {c.price_per_unit}€ a pezzo - peso{' '}
                            {c.weight}g{' '}
                          </li>
                        </ul>
                      );
                    })}
                  </div>

                  <div className="py-4">
                    <button
                      form="checkoutform"
                      type="submit"
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                      Aggiorna Ordine
                    </button>
                    <h1>{message}</h1>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div>Ordine inesistente</div>
          )}
        </main>
      </div>
    </div>
  );
}

export default NewOrder;

//   </div>
// </main>; */}
//       </div>
//     </div>
//   );
// }
