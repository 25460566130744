import React, { useState } from 'react';

async function POST(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function AppsPanel({ update, cartId, inventory }) {
  const postCart = async (prod) => {
    await POST(process.env.REACT_APP_BASE_URL + '/cart/admin/' + cartId, { ...inventory }).then(
      (data) => {
        console.log(data);
        setProducts(products);
        update();
      },
    );
  };

  const [products, setProducts] = useState(inventory.products);
  // const [emptyProd, setEmptyProd] = useState({
  //   name: 'Prodotto Vuoto',
  //   category: '',
  //   price: 1,
  //   weight: 1,
  //   slug: 'ProdEmpty',
  //   price_per_unit: 1,
  //   product: 'Empty',
  //   sku: 'Empty',
  // });

  console.log(products);
  console.log(inventory.products);

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6">
        {/* Connected Apps cards */}
        <section className="pb-6 border-b border-gray-200">
          <div className="grid grid-cols-12 gap-6">
            {/*free product*/}

            {console.log(products)}
            {console.log(inventory.products)}

            {inventory && inventory.products && inventory.products.length > 0
              ? inventory.products?.map((product, index) => {
                  return (
                    <div
                      key={index}
                      className="col-span-full md:col-span-4 xl:col-span-3 2xl:col-span-2 bg-white shadow-md rounded-sm border border-gray-200"
                    >
                      {/* Card content */}
                      <div className="flex flex-col h-full p-5">
                        <div className="flex-grow">
                          <header className="flex items-center mb-4">
                            <h3 className="text-lg text-gray-800 font-semibold m-auto text-center">
                              {product.name}
                            </h3>
                          </header>
                          <h3 className="text-lg text-gray-800 font-semibold m-auto text-center">
                            {product.category} ( {product.price} &euro; )
                          </h3>
                          <div className="text-lg text-center">
                            <span>Peso</span>
                            <input
                              className="border-4 w-32"
                              placeholder={product.weight}
                              onChange={(e) => {
                                let prodTemp = products;
                                prodTemp[index].weight = parseInt(e.target.value);
                                setProducts(prodTemp);
                              }}
                            ></input>
                          </div>
                        </div>
                        {/* Card footer */}
                        <footer className="mt-4">
                          <div className="flex flex-wrap justify-center items-center">
                            {/* Right side */}
                            <button
                              className="btn-lg border-gray-200 hover:border-gray-300 shadow-sm  items-center"
                              onClick={() => {
                                console.log(product);
                                postCart(product);
                              }}
                            >
                              <span>+</span>
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  );
                })
              : () => {
                  return <></>;
                }}
          </div>
        </section>
      </div>
    </div>
  );
}

export default AppsPanel;
